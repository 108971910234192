import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { FaCheckCircle } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { postData } from '../../global/Api';
import { APIENDPOINT } from '../../global/Const';
import Spinner from '../../components/common/Spinner';
//SVG
import '../../css/main.css';
import Header from '../../components/common/Header';
import { getFormattedString } from '../../global/Function';

function PaymentFail() {
    const navigate = useNavigate()
    const { counter_id } = useParams();
    const [otp, setOTP] = useState('');
    const [email, setemail] = useState('');
    const [downloadEmail, setdownloadEmail] = useState('');
    const [is_email, setis_email] = useState('');
    const [mobile, setmobile] = useState('');
    const [apiResponse, setapiResponse] = useState('');
    const [validationType, setvalidationType] = useState('');
    const [isLoading, setisLoading] = useState(false);
    const [apiResponseList, setApiResponseList] = useState([]);
    const [sightseeing_id, setsightseeing_id] = useState('');
    const [errormessage, seterrormessage] = useState([]);
    const [is_mobile, setis_mobile] = useState('');
    const [order_id, setorder_id] = useState(null);
    const [errors, setErrors] = useState({});
    const [modal, setModal] = useState(false);
    const [emailModal, setEmailModal] = useState(false);
    const state = useSelector((state) => state);
    const toggle = () => setModal(!modal);
    const toggleEmail = () => setEmailModal(!emailModal);
    let orderIdImage = null

    const showPermissionDialog = () => {
        const { browser, device } = getBrowserAndDevice();
        let message = '';

        if (device === 'Mobile') {
            message += `Location access is required to use this feature. Please follow the instructions below to enable location services on your mobile device:\n\n`;
            if (browser === 'Chrome') {
                message += `**Chrome Mobile:** Go to Settings > Site settings > Location, and ensure location access is enabled.\n`;
            } else if (browser === 'Firefox') {
                message += `**Firefox Mobile:** Go to Settings > Privacy > Location, and ensure location access is enabled.\n`;
            } else if (browser === 'Safari') {
                message += `**Safari Mobile:** Go to Settings > Safari > Location, and make sure location access is enabled.\n`;
            } else {
                message += `For other mobile browsers, please check your browser’s settings to enable location access.\n`;
            }
        } else {
            message += `Location access is required to use this feature. Please follow the instructions below to enable location services on your desktop:\n\n`;
            if (browser === 'Chrome') {
                message += `**Chrome:** Go to Settings (chrome://settings) > Privacy and security > Site settings > Location, and ensure location access is enabled.\n`;
            } else if (browser === 'Firefox') {
                message += `**Firefox:** Go to Options (about:preferences) > Privacy & Security > Permissions > Location, and make sure location access is allowed.\n`;
            } else if (browser === 'Safari') {
                message += `**Safari:** Go to Preferences (Safari > Preferences) > Websites tab > Location, and allow location access for this site.\n`;
            } else if (browser === 'Edge') {
                message += `**Edge:** Go to Settings > Cookies and site permissions > Location, and make sure location access is enabled.\n`;
            } else if (browser === 'Internet Explorer') {
                message += `**Internet Explorer:** Go to Tools > Internet Options > Privacy tab > Location, and ensure location access is enabled.\n`;
            } else {
                message += `For other browsers, please check your browser’s settings to enable location access.\n`;
            }
        }

        alert(message);
    };

    const getBrowserAndDevice = () => {
        const userAgent = navigator.userAgent;
        let browser = '';
        let device = '';

        if (userAgent.includes('Firefox')) {
            browser = 'Firefox';
        } else if (userAgent.includes('Chrome') && !userAgent.includes('Edg')) {
            browser = 'Chrome';
        } else if (userAgent.includes('Safari') && !userAgent.includes('Chrome')) {
            browser = 'Safari';
        } else if (userAgent.includes('Edg')) {
            browser = 'Edge';
        } else if (userAgent.includes('MSIE') || userAgent.includes('Trident/')) {
            browser = 'Internet Explorer';
        }

        device = /Mobi|Android/i.test(userAgent) ? 'Mobile' : 'Desktop';

        return { browser, device };
    };

    const getCurrentLocation = () => {
        return new Promise((resolve, reject) => {
            if (!navigator.geolocation) {
                const errorMessage = "Geolocation is not supported by this browser.";
                toast.error(errorMessage);
                reject(errorMessage);
                return;
            }

            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    resolve({ latitude, longitude });
                },
                (error) => {
                    let errorMessage;

                    switch (error.code) {
                        case error.PERMISSION_DENIED:
                            errorMessage = "Location services are disabled. Please enable location services in your device settings to proceed.";
                            showPermissionDialog();
                            break;
                        case error.POSITION_UNAVAILABLE:
                            errorMessage = "Location information is unavailable.";
                            break;
                        case error.TIMEOUT:
                            errorMessage = "The request to get user location timed out.";
                            break;
                        case error.UNKNOWN_ERROR:
                            errorMessage = `An unknown error occurred: ${error.message}`;
                            break;
                        default:
                            errorMessage = `Error: ${error.message}`;
                    }

                    // toast.error(errorMessage);
                    seterrormessage(errorMessage);
                    reject(errorMessage);
                }
            );
        });
    };

    const getPlaceByCounter = async () => {
        setisLoading(true)
        try {
            const { latitude, longitude } = await getCurrentLocation();
            const formData = {
                counter_slug: counter_id.toString(),
                user_lat: latitude.toString(),
                user_long: longitude.toString()
            };
            const responseApi = await postData(APIENDPOINT.GETALLSIGHTSEEN, formData);

            if (responseApi.response_code === 200 && responseApi.status === true) {
                setApiResponseList(responseApi.data);
                setisLoading(false);

            } else {
                // toast.error(responseApi.message || "Something Went Wrong !!");
                seterrormessage(responseApi.message || "Something Went Wrong !!");
            }
        } catch (error) {
            console.log(error.message)
            // toast.error(`Error: ${error.message || 'Something Went Wrong'}`);
        } finally {
            setisLoading(false);
        }
    };

    const sendOTP = async (type) => {
        let formData = {};
        let error = {};
        const mobileRegex = /^(\+\d{1,3}[- ]?)?\d{10}$/;
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (!sightseeing_id) {
            error.sightseeing_id = "Please select sightseeing"
        }

        if (type === "Mobile") {
            if (!mobile || !mobileRegex.test(mobile)) {
                error.mobile = 'Please enter 10 digit mobile';
                setErrors(error);
                return;
            }
            formData = {
                mobile: mobile,
                sightseeing_id: sightseeing_id
                // verification_token: verification_token
            };
        }

        if (type === "Email") {
            if (!email || !emailRegex.test(email)) {
                error.email = 'Please enter valid email id';
                setErrors(error);
                return;
            }
            formData = {
                email: email,
                sightseeing_id: sightseeing_id
                // verification_token: verification_token
            };
        }
        setisLoading(true)
        const responseApi = await postData(APIENDPOINT.SENDTOKEN, formData);
        if (responseApi.response_code === 200 && responseApi.status === true) {
            toggle();
            setvalidationType(type);
            // setverification_token(responseApi.data.verification_token)
            setisLoading(false)
            setOTP('')
            setErrors({})
        } else if (responseApi.message) {
            toast.error(responseApi.message)
            setisLoading(false)
            setOTP('')
            setErrors({})
        } else {
            toast.error("Something Went Wrong !!")
            setisLoading(false)
            setOTP('')
            setErrors({})
        }
    };
    const verifyOTP = async (type) => {
        const formData = {
            "mobile": mobile,
            "sightseeing_id": sightseeing_id,
            "email": email,
            "otp": otp
        };
        let error = {};

        setisLoading(true)
        const responseApi = await postData(APIENDPOINT.VERIFYTOKEN, formData);
        if (responseApi.response_code === 200 && responseApi.status === true) {
            if (validationType === "Mobile") {
                setis_mobile(!is_mobile)
                toggle()
                setapiResponse(responseApi)
                setisLoading(false)
            }
            if (validationType === "Email") {
                setis_email(!is_email)
                toggle()
                setapiResponse(responseApi)
                setisLoading(false)
            }
        } else if (responseApi.message) {
            error.otp = responseApi.message
            setErrors(error)
            // toast.error(responseApi.message)
            setisLoading(false)
        } else {
            toast.error("Something Went Wrong !!")
            setisLoading(false)
        }
    };
    const downloadImage = async (forceDownload = false) => {
        setisLoading(true)
        const imageData = await downloadTicketByOrderid(orderIdImage);
        const imageURL = imageData?.ticket;
        const imageName = imageData?.file_name;
        // const imageURL = "https://upload.wikimedia.org/wikipedia/en/6/6b/Hello_Web_Series_%28Wordmark%29_Logo.png";
        if (!forceDownload) {
            const link = document.createElement("a");
            link.href = imageURL;
            link.download = imageName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } else {
            const imageBlob = await fetch(imageURL)
                .then(response => response.arrayBuffer())
                .then(buffer => new Blob([buffer], { type: "image/jpeg" }));

            // console.log(imageBlob, URL.createObjectURL(imageBlob));
            const link = document.createElement("a");
            link.href = URL.createObjectURL(imageBlob);
            link.download = imageName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
    const shareFile = (file, title, text) => {
        if (navigator.canShare && navigator.canShare({ files: [file] })) {
            navigator
                .share({
                    files: [file],
                    title,
                    text
                })
                .then(() => console.log("Share was successful."))
                .catch((error) => console.log("Sharing failed", error));
        } else {
            console.log(`Your system doesn't support sharing files.`);
        }
    };
    const createImage = (imageUrl) => {
        if (!imageUrl) {
            console.log("No image URL provided.");
            return;
        }
        const filename = imageUrl.substring(imageUrl.lastIndexOf('/') + 1);
        fetch(imageUrl)
            .then(response => response.blob())
            .then(blob => {
                const file = new File([blob], filename, { type: blob.type });
                if (file) {
                    shareFile(file, "Eternal Mewar", window.location.href);
                } else {
                    console.log("Failed to create file from image URL.");
                }
            })
            .catch(error => {
                console.log("Error fetching image:", error);
            });
    };
    const handleShare = async (orderId) => {
        const imageData = await downloadTicketByOrderid(orderId);
        createImage(imageData.ticket)
        return
    };
    async function downloadTicketByOrderid(orderId) {
        const formDat = {
            "order_id": orderId
        }
        setisLoading(true)
        const resposeApi = await postData(APIENDPOINT.DOWNLOADTICKET, formDat)
        if (resposeApi.response_code === 200 && resposeApi.status === true) {
            setisLoading(false)
            return resposeApi.data
        } else if (resposeApi.message) {
            toast.error(resposeApi.message)
            setisLoading(false)
        } else {
            toast.error("Something Went Wrong !!")
            setisLoading(false)
        }
    }
    async function downloadTicketOnEmail() {
        let error = {};
        const formDat = {
            "order_id": order_id,
            // "order_id": "721830862291",
            "to_email": downloadEmail
        }
        setisLoading(true)
        const resposeApi = await postData(APIENDPOINT.EMAILTICKET, formDat)
        if (resposeApi.response_code === 200 && resposeApi.status === true) {
            setisLoading(false)
            toast.success(resposeApi.message)
            toggleEmail()
        } else if (resposeApi.message) {
            // toast.error(resposeApi.message)
            error.downloadEmail = resposeApi.message
            setErrors(error)
            setisLoading(false)
        } else {
            toast.error("Something Went Wrong !!")
            setisLoading(false)
        }
    }
    const handleEmail = async () => {
        let error = {};
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!downloadEmail || !emailRegex.test(downloadEmail)) {
            error.downloadEmail = "Please enter valid email id"
            setErrors(error)
            return;
        } else {
            downloadTicketOnEmail()
            setErrors({})
            // toggle()
        }
    }

    useEffect(() => {
        getPlaceByCounter()
        return () => {
        }
    }, [])


    return (
        <div className="App">
            <Header style={2} counterId={counter_id} />
            <Spinner loading={isLoading} />
            <div className="SupportContent">
                <div className="container">
                    <Modal
                        isOpen={modal}
                        toggle={toggle}
                        backdrop={'static'}
                        keyboard={false}
                    >
                        <ModalHeader toggle={toggle}>Verify OTP</ModalHeader>
                        <ModalBody>
                            <div className='col-12'>
                                <input
                                    maxLength={6}
                                    type="text"
                                    placeholder="XXXXXX"
                                    className="col form-control p-2 bg-custom-input"
                                    value={otp}
                                    onChange={(e) => {
                                        const enteredValue = e.target.value;
                                        const numericValue = enteredValue.replace(/\D/g, '');
                                        setOTP(numericValue);
                                    }}
                                    style={{ letterSpacing: 10, fontSize: 20, textAlign: 'center' }}
                                />
                                <p className='pt-1 text-center text-danger m-0'>{errors.otp}</p>
                            </div>
                        </ModalBody>


                        <ModalFooter>
                            <Button className='bg-custom-color-secondary' style={{ borderColor: "#AA4332" }} onClick={() => verifyOTP()}>
                                Submit
                            </Button>
                            {''}
                            <Button color="secondary" onClick={toggle}>
                                Cancel
                            </Button>
                        </ModalFooter>
                    </Modal>

                    <Modal
                        isOpen={emailModal}
                        toggle={toggleEmail}
                        backdrop={'static'}
                        keyboard={false}
                    >
                        <ModalHeader toggle={toggleEmail}>E-Mail Ticket</ModalHeader>
                        <ModalBody>
                            <div className='col-12'>
                                <input type="text" placeholder="Email Address" className="col form-control p-2 bg-custom-input" onChange={(e) => setdownloadEmail(e.target.value)} />
                                <p className='pt-1 ps-1 text-danger m-0'>{errors.downloadEmail}</p>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <Button disabled={isLoading} className='bg-custom-color-secondary' style={{ borderColor: "#AA4332" }} onClick={handleEmail}>
                                Submit
                            </Button>
                            {''}
                            <Button disabled={isLoading} color="secondary" onClick={toggleEmail}>
                                Cancel
                            </Button>
                        </ModalFooter>
                    </Modal>
                    {apiResponse.data ?
                        <>
                            <p className="text-center pt-4 m-0" style={{ fontSize: 14 }}>Please access your event tickets below.If you encounter any difficulties, click here to contact our administration team.</p>
                            {apiResponse?.data?.map((item) => {
                                return (
                                    <>
                                        <div className="confirm-ticket-background mt-4 px-3 py-2">
                                            <div className="row">
                                                {item?.qrcode &&
                                                    <div className="col p-0">
                                                        <img src={item?.qrcode} style={{ width: "100%", height: "auto" }} className="rounded" alt="QR Code" />
                                                        <p className="px-2 m-0 text-center" style={{ fontSize: 12, fontWeight: 500 }}>Check Points :</p>
                                                        {item?.checkPoints?.map((item, index) => {
                                                            return (
                                                                <p className="m-0 px-2 text-center" style={{ fontSize: 10 }}>{index + 1}. {item}</p>
                                                            )
                                                        })}
                                                    </div>
                                                }
                                                <div className="col">
                                                    <div className="col text-color-primary fw-bold" style={{ alignContent: 'center' }}>
                                                        {/* <h5 className="py-3 m-0">{item.sightseeing_name}</h5> */}
                                                        <h5 className="py-2 m-0 fw-bold">{item.sightseeing_name}</h5>
                                                        <label className='m-0 text-dark fs-14'>{item.product_name}</label>
                                                    </div>
                                                    <div className="mt-2 d-flex">
                                                        <img src={require('../../assets/icon/ticket_icon.png')} className="custom-icon me-2" alt="Date Icon" />
                                                        <p className="fs-13" style={{ marginTop: 1 }}>{item.ticket_no}</p>
                                                    </div>
                                                    <div className="d-flex">
                                                        <img src={require('../../assets/icon/date_icon.png')} className="custom-icon me-2" alt="Date Icon" />
                                                        <p className="fs-13" style={{ marginTop: 1 }}>{item.visit_date}</p>
                                                    </div>
                                                    <div className="d-flex">
                                                        <img src={require('../../assets/icon/gate.png')} className="custom-icon me-2" alt="Time Icon" />
                                                        <p className="fs-13" style={{ marginTop: 1 }}>{item.entry_gate}</p>
                                                    </div>
                                                    <div className="d-flex">
                                                        <img src={require('../../assets/icon/time.png')} className="custom-icon me-2" alt="Time Icon" />
                                                        <p className="fs-13" style={{ marginTop: 1 }}>{item.product_validity} IST</p>
                                                    </div>
                                                    {item?.sale_head?.map((itemSale) => {
                                                        return (
                                                            <div className="d-flex">
                                                                <img src={require('../../assets/icon/adult.png')} className="custom-icon me-2" alt="Adult Icon" />
                                                                <p className="fs-13" style={{ marginTop: 1 }}>{itemSale.name} : {itemSale.no_of_visitor}</p>
                                                            </div>
                                                        )
                                                    })}
                                                    <div className="d-flex">
                                                        <img src={require('../../assets/icon/adult.png')} className="custom-icon me-2" alt="Time Icon" />
                                                        <p className="fs-13" style={{ marginTop: 1 }}>Total Visitors : {item.total_no_of_visitors}</p>
                                                    </div>
                                                    <div className="d-flex">
                                                        <img src={require('../../assets/icon/rupeee.png')} className="custom-icon me-2" alt="Rupee Icon" />
                                                        <p className="fs-13" style={{ marginTop: 1 }}>{parseFloat(item.grand_total).toFixed(2)}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr className="mx-0 my-2" />
                                            <div style={{ padding: 3, borderRadius: 2 }}>
                                                <p className="m-0" style={{ fontSize: 11 }}>Payment Mode : {item?.payment?.mode}</p>
                                                <p className="m-0" style={{ fontSize: 11 }}>Order id : {item?.order_number}</p>
                                                <p className="m-0" style={{ fontSize: 11 }}>Transaction ID : {item?.payment?.transaction_id}</p>
                                                <p className="m-0" style={{ fontSize: 11 }}>Transaction Date : {item?.payment?.date}</p>
                                            </div>
                                            <hr className="mx-0 my-2" />
                                            <div className="row justify-content-between">
                                                <div className="col-3 d-flex flex-column align-items-center" onClick={() => {
                                                    orderIdImage = item.order_number
                                                    downloadImage(item.order_number)
                                                }} style={{ cursor: 'pointer' }}>
                                                    <img src={require('../../assets/icon/ticket.png')} className="custom-icon-footer" alt="Ticket Icon" />
                                                    <p className="text-center fs-13">Download Ticket</p>
                                                </div>
                                                <div className="col-3 d-flex flex-column align-items-center" onClick={() => {
                                                    handleShare(item.order_number)
                                                }} style={{ cursor: 'pointer' }}>
                                                    <img src={require('../../assets/icon/share.png')} className="custom-icon-footer" alt="Share Icon" />
                                                    <p className="text-center fs-13">Share Ticket</p>
                                                </div>
                                                <div className="col-3 d-flex flex-column align-items-center" onClick={() => {
                                                    setorder_id(item.order_number)
                                                    toggleEmail()
                                                    setErrors({})
                                                }} style={{ cursor: 'pointer' }}>
                                                    <img src={require('../../assets/icon/email.png')} className="custom-icon-footer" alt="Email Icon" />
                                                    <p className="text-center fs-13">Email Ticket</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="TermsConditions">
                                            <div dangerouslySetInnerHTML={{ __html: item?.instructions }} />
                                        </div>
                                        {/* <div className="TermsConditions">
                                            <ol className="m-0" style={{ paddingLeft: 9 }}>
                                                {item?.sale_head_Defination.map((item, index) => (
                                                    <li key={index}>{item}</li>
                                                ))}
                                            </ol>
                                        </div> */}
                                        <div className="TermsConditions">
                                            <ol className="m-0" style={{ paddingLeft: 9 }}>
                                                {item?.sale_head_Defination.map((item, index) => (
                                                    <li key={index}>
                                                        {item.includes('\n') || item.includes('\\n')
                                                            ? item.split(/\\n|\n/).map((line, lineIndex) => (
                                                                <span key={`${index}-${lineIndex}`}>{line}<br /></span>
                                                            ))
                                                            : item}
                                                    </li>
                                                ))}
                                            </ol>
                                        </div>
                                        <div className="TermsConditions">
                                            <h5>Terms & Conditions :</h5>
                                            {/* <ol className="px-3 m-0">
                                    {ticketItems.map((item, index) => (
                                        item.terms_conditions.trim() && <li key={index}>{item.terms_conditions.trim()}</li>
                                    ))}
                                </ol> */}
                                            <div dangerouslySetInnerHTML={{ __html: item?.terms_and_conditions }} />
                                            {item?.terms_and_conditions_hindi &&
                                                <div dangerouslySetInnerHTML={{ __html: item?.terms_and_conditions_hindi }} />
                                            }
                                        </div>
                                    </>
                                )
                            })
                            }
                        </>
                        :
                        <>
                            <h3 className="text-center py-3 m-0">How do I access or download my ticket if there's an issue?</h3>
                            <p className="text-center py-4 m-0" style={{ fontSize: 14 }}>Please enter your registered mobile number or email address to check the status of your ticket.</p>
                            <div className="row mt-4 justify-content-center px-3">
                                <div className="col-3 p-0 text-left align-self-center fw-bold">
                                    <span className='fs-13'>Sightseeing <span className='text-danger'>*</span></span>
                                </div>
                                <div className="col-9 text-center p-0">
                                    {/* <div className="input-group"> */}
                                    <select
                                        className="form-control p-2 bg-custom-input"
                                        id="exampleFormControlSelect1"
                                        value={sightseeing_id}
                                        onChange={(e) => setsightseeing_id(e.target.value)}
                                    >
                                        <option value={""}>Select Sightseeing</option>
                                        {apiResponseList.list?.map((item, index) => {
                                            return (
                                                <option value={item.id}>{item.name}</option>
                                            )
                                        })}
                                    </select>
                                    {/* <div className='bg-custom-input align-content-center'>
                                    <BsChevronDown className='mx-2' />
                                </div>
                            </div> */}
                                </div>
                                <p className='py-1 error-custom text-danger m-0'>{errors.sightseeing_id}</p>
                            </div>
                            <div className="row mt-4 justify-content-center px-3">
                                <div className="col-3 p-0 text-left align-self-center fw-bold">
                                    <span className='fs-13'>Email <span className='text-danger'>*</span></span>
                                </div>
                                <div className="col-9 text-center p-0">
                                    <div className="input-group">
                                        <input
                                            type="text"
                                            className="form-control p-2 bg-custom-input"
                                            placeholder="Email Address"
                                            value={email}
                                            onChange={(e) => {
                                                setemail(e.target.value)
                                            }}
                                        />
                                        <button type="button" className="btn bg-custom-input text-color-primary text-decoration-none border-none" onClick={() => sendOTP("Email")}>
                                            {is_email ? <FaCheckCircle className="ml-2 text-success" /> : "Verify"}
                                        </button>
                                    </div>
                                </div>
                                <p className='py-1 error-custom text-danger m-0'>{errors.email}</p>
                            </div>
                            <div className="row mt-4 justify-content-center px-3">
                                <div className="col-3 p-0 text-left align-self-center fw-bold">
                                    <span className='fs-13'> <span className='text-danger'></span></span>
                                </div>
                                <div className="col-9 text-center p-0">
                                    <div className="separator">OR</div>
                                </div>
                            </div>
                            <div className="row mt-4 justify-content-center px-3">
                                <div className="col-3 p-0 text-left align-self-center fw-bold">
                                    <span className='fs-13'>Mobile <span className='text-danger'>*</span></span>
                                </div>
                                <div className="col-9 text-center p-0">
                                    <div className="input-group">
                                        <span className="input-group-text rounded">+91</span>
                                        <input
                                            type="text"
                                            maxLength={10}
                                            className="form-control p-2 bg-custom-input"
                                            placeholder="Mobile Number"
                                            value={mobile}
                                            onChange={(e) => {
                                                const input = e.target.value;
                                                const filteredInput = input.replace(/\D/g, '');
                                                setmobile(filteredInput)
                                            }}
                                        />
                                        <button type="button" className="btn bg-custom-input text-color-primary text-decoration-none border-none" onClick={() => sendOTP("Mobile")}>
                                            {is_mobile ? <FaCheckCircle className="ml-2 text-success" /> : "Verify"}
                                        </button>
                                    </div>
                                </div>
                                <p className='py-1 error-custom text-danger m-0'>{errors.mobile}</p>
                            </div>
                        </>
                    }
                </div>
            </div>

            <footer className="Footer">
                <div style={{ height: 25, width: "100%", backgroundColor: "#FAF7F2", borderBottomLeftRadius: 25, borderBottomRightRadius: 25 }}></div>
                <div className="FooterCompoenent">
                    <div onClick={() => navigate(-1)}>
                        <img src={require('../../assets/icon/left_arrow.png')} className="custom-icon-main-footer me-3" />
                        <span className='fs-6'>PREVIOUS</span>
                    </div>
                </div>
            </footer>
        </div>
    );
}

export default PaymentFail;
