import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import { getFormattedString } from "../global/Function";
import { toast } from "react-toastify";
import { APIENDPOINT } from "../global/Const";
import { postData } from "../global/Api";
import { resetData } from "../redux/actionTypes/rootAction";
import Header from "../components/common/Header";
import CustomButton from '../components/common/Button';
import Spinner from "../components/common/Spinner";
import '../css/main.css';

function Confirmbooking() {
    const { counter_id, order_id } = useParams();
    const [modal, setModal] = useState(false);
    const [email, setEmail] = useState(false);
    const [isLoading, setisLoading] = useState(true)
    const [response, setResponse] = useState([])
    const [errorMessage, setMessage] = useState()
    const [instructions, setInstructions] = useState(null);
    const [terms, setTerms] = useState('');
    const [termsHindi, setTermsHindi] = useState('');
    const [ticketOnly, setticketOnly] = useState(false)
    const toggle = () => setModal(!modal);
    const state = useSelector((state) => state);
    const [errors, setErrors] = useState({});
    const dispatch = useDispatch();

    useEffect(() => {
        const matchurl = window.location.href.includes("view-ticket")
        if (matchurl) {
            setticketOnly(true)
        } else {
            setticketOnly(false)
        }
        getorderbyid()
    }, [])

    const downloadImage = async (forceDownload = false) => {
        setisLoading(true)
        const imageData = await downloadTicketByOrderid();
        const imageURL = imageData?.ticket;
        const imageName = imageData?.file_name;
        // const imageURL = "https://upload.wikimedia.org/wikipedia/en/6/6b/Hello_Web_Series_%28Wordmark%29_Logo.png";
        if (!forceDownload) {
            const link = document.createElement("a");
            link.href = imageURL;
            link.download = imageName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } else {
            const imageBlob = await fetch(imageURL)
                .then(response => response.arrayBuffer())
                .then(buffer => new Blob([buffer], { type: "image/jpeg" }));

            // console.log(imageBlob, URL.createObjectURL(imageBlob));
            const link = document.createElement("a");
            link.href = URL.createObjectURL(imageBlob);
            link.download = imageName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
    const shareFile = (file, title, text) => {
        if (navigator.canShare && navigator.canShare({ files: [file] })) {
            navigator
                .share({
                    files: [file],
                    title,
                    text
                })
                .then(() => console.log("Share was successful."))
                .catch((error) => console.log("Sharing failed", error));
        } else {
            console.log(`Your system doesn't support sharing files.`);
        }
    };
    const createImage = (imageUrl) => {
        if (!imageUrl) {
            console.log("No image URL provided.");
            return;
        }
        const filename = imageUrl.substring(imageUrl.lastIndexOf('/') + 1);
        fetch(imageUrl)
            .then(response => response.blob())
            .then(blob => {
                const file = new File([blob], filename, { type: blob.type });
                if (file) {
                    shareFile(file, "Eternal Mewar", window.location.href);
                } else {
                    console.log("Failed to create file from image URL.");
                }
            })
            .catch(error => {
                console.log("Error fetching image:", error);
            });
    };
    const handleShare = async () => {
        const imageData = await downloadTicketByOrderid();
        createImage(imageData.ticket)
        return
    };
    async function getorderbyid() {
        const formDat = {
            "order_id": order_id.toString(),
        }
        const resposeApi = await postData(APIENDPOINT.ORDERBYID, formDat)
        if (resposeApi.response_code === 200 && resposeApi.status === true) {
            setResponse(resposeApi.data)
            setInstructions(resposeApi.data.special_instructions);
            setTerms(resposeApi.data.terms_and_conditions);
            setTermsHindi(resposeApi.data.terms_and_conditions_hindi);
            dispatch(resetData());
            setisLoading(false)
        } else if (resposeApi.message) {
            setMessage(resposeApi.message)
            toast.error(resposeApi.message)
            setisLoading(false)
        } else {
            toast.error("Something Went Wrong !!")
            setisLoading(false)
        }
    }
    async function downloadTicketByOrderid() {
        const formDat = {
            "order_id": order_id.toString(),
        }
        setisLoading(true)
        const resposeApi = await postData(APIENDPOINT.DOWNLOADTICKET, formDat)
        if (resposeApi.response_code === 200 && resposeApi.status === true) {
            setisLoading(false)
            return resposeApi.data
        } else if (resposeApi.message) {
            toast.error(resposeApi.message)
            setisLoading(false)
        } else {
            toast.error("Something Went Wrong !!")
            setisLoading(false)
        }
    }
    async function downloadTicketOnEmail() {
        let error = {};
        const formDat = {
            "order_id": order_id.toString(),
            // "order_id": "721830862291",
            "to_email": email
        }
        setisLoading(true)
        const resposeApi = await postData(APIENDPOINT.EMAILTICKET, formDat)
        if (resposeApi.response_code === 200 && resposeApi.status === true) {
            setisLoading(false)
            toast.success(resposeApi.message)
            toggle()
        } else if (resposeApi.message.length) {
            // toast.error(resposeApi.message)
            error.email = resposeApi?.message
            setErrors(error)
            setisLoading(false)
        } else {
            toast.error("Something Went Wrong !!")
            setisLoading(false)
        }
    }
    const handleEmail = async () => {
        let error = {};
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!email || !emailRegex.test(email)) {
            error.email = "Please enter valid email id"
            setErrors(error)
            return;
        } else {
            downloadTicketOnEmail()
            setErrors({})
            // toggle()
        }
    }
    return (
        <React.Fragment>
            <div className="App">
                <Header sublogo={!ticketOnly && state.sub_logo} mainlogo={!ticketOnly && state.main_logo} style={ticketOnly ? 2 : 3} counterId={counter_id} />
                <Spinner loading={isLoading} />
                <div className="ConfirmContainer">
                    <div className="container">

                        <Modal
                            isOpen={modal}
                            toggle={toggle}
                            backdrop={'static'}
                            keyboard={false}
                        >
                            <ModalHeader toggle={toggle}>E-Mail Ticket</ModalHeader>
                            <ModalBody>
                                <div className='col-12'>
                                    <input type="text" placeholder="Email Address" className="col form-control p-2 bg-custom-input" onChange={(e) => setEmail(e.target.value)} />
                                    <p className='pt-1 ps-1 text-danger m-0'>{errors.email}</p>
                                </div>
                            </ModalBody>
                            <ModalFooter>
                                <Button disabled={isLoading} className='bg-custom-color-secondary' style={{ borderColor: "#AA4332" }} onClick={handleEmail}>
                                    Submit
                                </Button>
                                {''}
                                <Button disabled={isLoading} color="secondary" onClick={toggle}>
                                    Cancel
                                </Button>
                            </ModalFooter>
                        </Modal>


                        {(response.sightseeing_name || response.product_name) &&
                            <div className="row text-left d-flex justify-content-center pt-3" style={{ alignItems: 'center' }}>
                                <img src={require("../assets/icon/green_tick.png")} alt="header-logo" className="Logo my-3 col-3" />
                                <div className="col-9">
                                    <h5>Ticket Booking Successful</h5>
                                    <h6>Find Below Your Ticket</h6>
                                </div>
                            </div>}
                        {(response.sightseeing_name || response.product_name) ?
                            <>
                                <div className="confirm-ticket-background mt-4 px-3 py-2">
                                    <div className="row">
                                        {response.qrcode &&
                                            <div className="col p-0">
                                                <img src={response.qrcode} style={{ width: "100%", height: "auto" }} className="rounded" alt="QR Code" />
                                                <p className="px-2 m-0 text-center" style={{ fontSize: 12, fontWeight: 500 }}>Check Points :</p>
                                                {response?.checkPoints.map((item, index) => {
                                                    return (
                                                        <p className="m-0 px-2 text-center" style={{ fontSize: 10 }}>{index + 1}. {item}</p>
                                                    )
                                                })}
                                            </div>
                                        }
                                        <div className="col">
                                            <div className="col text-color-primary" style={{ alignContent: 'center' }}>
                                                <h5 className="py-2 m-0 fw-bold">{response.sightseeing_name}</h5>
                                                <label className='m-0 text-dark fs-14'>{response.product_name}</label>
                                            </div>
                                            <div className="mt-2 d-flex">
                                                <img src={require('../assets/icon/ticket_icon.png')} className="custom-icon me-2" alt="Date Icon" />
                                                <p className="fs-13" style={{ marginTop: 1 }}>{response.ticket_no}</p>
                                            </div>
                                            <div className="d-flex">
                                                <img src={require('../assets/icon/date_icon.png')} className="custom-icon me-2" alt="Date Icon" />
                                                <p className="fs-13" style={{ marginTop: 1 }}>{getFormattedString(response.visit_date)}</p>
                                            </div>
                                            <div className="d-flex">
                                                <img src={require('../assets/icon/gate.png')} className="custom-icon me-2" alt="Time Icon" />
                                                <p className="fs-13" style={{ marginTop: 1 }}>{response.entry_gate}</p>
                                            </div>
                                            <div className="d-flex">
                                                <img src={require('../assets/icon/time.png')} className="custom-icon me-2" alt="Time Icon" />
                                                <p className="fs-13" style={{ marginTop: 1 }}>{response.product_validity} IST</p>
                                            </div>
                                            {response?.sale_head?.map((item) => {
                                                return (
                                                    <div className="d-flex">
                                                        <img src={require('../assets/icon/adult.png')} className="custom-icon me-2" alt="Adult Icon" />
                                                        <p className="fs-13" style={{ marginTop: 1 }}>{item.name} : {item.no_of_visitors}</p>
                                                    </div>
                                                )
                                            })}
                                            <div className="d-flex">
                                                <img src={require('../assets/icon/adult.png')} className="custom-icon me-2" alt="Time Icon" />
                                                <p className="fs-13" style={{ marginTop: 1 }}>Total Visitors : {response.total_no_of_visitors}</p>
                                            </div>
                                            <div className="d-flex">
                                                <img src={require('../assets/icon/rupeee.png')} className="custom-icon me-2" alt="Rupee Icon" />
                                                <p className="fs-13" style={{ marginTop: 1 }}>{parseFloat(response.grand_total).toFixed(2)}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <hr className="mx-0 my-2" />
                                    <div style={{ padding: 3, borderRadius: 2 }}>
                                        <p className="m-0" style={{ fontSize: 11 }}>Payment Mode : {response?.payment?.mode}</p>
                                        <p className="m-0" style={{ fontSize: 11 }}>Order id : {order_id}</p>
                                        <p className="m-0" style={{ fontSize: 11 }}>Transaction ID : {response?.payment?.transaction_id}</p>
                                        <p className="m-0" style={{ fontSize: 11 }}>Transaction Date : {response?.payment?.date}</p>
                                    </div>
                                    <hr className="mx-0 my-2" />
                                    <div className="row justify-content-between">
                                        <div className="col-3 d-flex flex-column align-items-center" onClick={downloadImage} style={{ cursor: 'pointer' }}>
                                            <img src={require('../assets/icon/ticket.png')} className="custom-icon-footer" alt="Ticket Icon" />
                                            <p className="text-center fs-13">Download Ticket</p>
                                        </div>
                                        {/* <div className="col-3 d-flex flex-column align-items-center" style={{ cursor: 'pointer' }}>
                                        <img src={require('../assets/icon/download.png')} className="custom-icon-footer" alt="Download Icon" />
                                        <p className="text-center fs-13">Download Receipt</p>
                                    </div> */}
                                        <div className="col-3 d-flex flex-column align-items-center" onClick={handleShare} style={{ cursor: 'pointer' }}>
                                            <img src={require('../assets/icon/share.png')} className="custom-icon-footer" alt="Share Icon" />
                                            <p className="text-center fs-13">Share Ticket</p>
                                        </div>
                                        <div className="col-3 d-flex flex-column align-items-center" onClick={() => {
                                            toggle()
                                            setErrors({})
                                        }} style={{ cursor: 'pointer' }}>
                                            <img src={require('../assets/icon/email.png')} className="custom-icon-footer" alt="Email Icon" />
                                            <p className="text-center fs-13">Email Ticket</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="TermsConditions">
                                    <div dangerouslySetInnerHTML={{ __html: instructions }} />
                                </div>
                                <div className="TermsConditions">
                                    <ol className="m-0" style={{ paddingLeft: 9 }}>
                                        {response?.sale_head_Defination.map((item, index) => (
                                            <li key={index}>
                                                {item.includes('\n') || item.includes('\\n')
                                                    ? item.split(/\\n|\n/).map((line, lineIndex) => (
                                                        <span key={`${index}-${lineIndex}`}>{line}<br /></span>
                                                    ))
                                                    : item}
                                            </li>
                                        ))}
                                    </ol>
                                </div>
                                <div className="TermsConditions">
                                    <h5>Terms & Conditions :</h5>
                                    {/* <ol className="px-3 m-0">
                                    {ticketItems.map((item, index) => (
                                        item.terms_conditions.trim() && <li key={index}>{item.terms_conditions.trim()}</li>
                                    ))}
                                </ol> */}
                                    <div dangerouslySetInnerHTML={{ __html: terms }} />
                                    {termsHindi &&
                                        <div dangerouslySetInnerHTML={{ __html: termsHindi }} />
                                    }
                                </div>
                            </> :
                            !isLoading &&
                            <div>
                                <h3 className="text-center py-5">Payment Failed !!</h3>
                                <p style={{ color: "black" }} >{errorMessage}</p>
                            </div>
                        }
                        {!ticketOnly &&
                            <div className="py-5 bottom-0 d-flex justify-content-center w-100">
                                <CustomButton color={"#AA4332"} text={"Buy Another Ticket"} width={100} href={process.env.PUBLIC_URL + `/counter/${counter_id}`} />
                            </div>
                        }
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default Confirmbooking;
